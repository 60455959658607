import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getPoolMatches } from "../../api/pool";
import { Loading } from "../../components/Loading";
import { PageBody } from "../../components/PageBody";
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/solid';
import { PageHeader } from "../../components/Headers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GroupRankingTable } from "../../components/pool/GroupRankingTable";
// import axios from 'axios';
// import { lusolve } from 'mathjs';


const matchTableSort = (matchA, matchB, key, direction='desc', defaultKey='new_id') => {
    if (direction === 'desc') {
        if (matchA[key] > matchB[key]) return -1;
        if (matchA[key] < matchB[key]) return 1;

        // Default sort desc if above are equal
        return matchB[defaultKey] - matchA[defaultKey];
    };

    if (matchA[key] > matchB[key]) return 1;
    if (matchA[key] < matchB[key]) return -1;

    return matchA[defaultKey] - matchB[defaultKey];
};


export function PoolMatches() {
    const { isLoading, isError, data, error } = useQuery("poolMatches", getPoolMatches);
    const [poolMatches, setPoolMatches] = useState(null);
    const tableSortColumn = 'game_datetime';
    const [tableSortDirection, setTableSortDirection] = useState('desc');

    useEffect(() => {
        if (!isError && !isLoading) {
            let matches = data.data.map(match => {
                return {...match, game_datetime: new Date(match.game_datetime.replace(/-/g, "/"))}
            }).sort((a, b) => matchTableSort(a, b, tableSortColumn, tableSortDirection));
            setPoolMatches(matches);
        }
    // eslint-disable-next-line
    }, [data, isError, isLoading]);

    const updateTableSortDirection = ( direction ) => {
        setTableSortDirection(direction);
        poolMatches.sort((a, b) => matchTableSort(a, b, tableSortColumn, direction));
    };

    // const fetchScores = () => {
    //     axios.post('/api/pool/ingest_gsheets_scores', {})
    //         .then(function (response) {
    //             console.log(response);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // };

    if (isLoading) {
        return <Loading />
    };

    if (isError) {
        return <span>Error: {error.message}</span>
    };

    if (poolMatches === null) {
        return <Loading />
    };

    return (
        <PageBody widthClass="max-w-screen-lg">
            <Helmet>
                <title>Amateur Pool Matches & Rankings | VizBadger</title>
                <meta name="description" content="Recorded games between amateur pool players to create dynamic ranking table to crown the winner."></meta>
                <meta name="keywords" content="Sports ranking systems, Amateur sports, Sports data collection, Colley ranking system"></meta>
            </Helmet>
            <PageHeader title="Amateur Pool Matches & Rankings" />
            <div className="flex flex-col sm:flex-row items-center sm:items-start">
                <div className="text-sm sm:text-base text-center sm:text-left mb-6 w-full sm:w-4/5 sm:border-r border-dotted sm:pr-4">
                        <p className="mb-2">I collected the results of pool games between a group of friends, and then implemented a ranking system to show who was the best.</p>
                        <p className="mb-2">If you want to rank teams in a league system then the ideal way is what we see in professional sport, equal number of games and then a points system for wins/losses. Even this suffers from a 'strength of schedule', but across the whole season you have a sense of equality in the fixtures.</p>
                        <p className="mb-2">Meetups with friends, and usually different lineups for an evening's pool mean that you have to implement a system that handles an uneven number of games between players in the 'group'.</p>
                        <p className="mb-2">My main ranking system is the Colley ranking system, with an addition of decay of results to give preferential scoring to games played more recently. An interesting feature of this ranking system is that all ratings are updated upon a result, regardless of the teams involved. This is because as a team's rating changes it changes the importance of previous fixtures played by that team.</p>
                        <p>E.g. if you beat a team in week 1, without any prior knowledge, it is a good thing. It becomes more impressive if that team goes on to beat every other team for the rest of the year. That initial win becomes more valuable as you realise the real 'strength' of your opponents.</p>
                </div>
                <div className="w-2/3 sm:w-1/5 sm:ml-8 mb-8 sm:mb-0">
                    <p className="tracking-tight text-center mb-3 ">Current Group Standings</p>
                    <div className="text-sm mb-6">
                        <GroupRankingTable groupUrlSlug={"banff"} />
                        <GroupRankingTable groupUrlSlug={"hurricane"} divClasses="mt-6" />
                    </div>
                </div>
            </div>
            <div className="text-xs sm:text-sm">
                <table className="w-full text-center">
                    <thead>
                        <tr className="bg-gray-200 tracking-tight">
                            <th className="p-1">Game #</th>
                            <th className="p-1">Player 1</th>
                            <th className="p-1">Score 1</th>
                            <th className="p-1">Score 2</th>
                            <th className="p-1">Player 2</th>
                            <th
                                className="cursor-pointer p-1 flex items-center justify-center"
                                onClick={() => updateTableSortDirection(tableSortDirection === 'desc' ? 'asc' : 'desc')}
                            >
                                Game Date{tableSortDirection === 'desc' ? <BarsArrowDownIcon className="ml-1 h-3 w-3"/> : <BarsArrowUpIcon className="ml-1 h-3 w-3"/>}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {poolMatches.map(match => (
                            <tr key={match.new_id}>
                                <td>{match.new_id}</td>
                                <td>
                                    <Link
                                        to={`/pool/player/${match.player_1_id}`}
                                        className="hover:underline cursor-pointer"
                                    >
                                        {match.player_1}
                                    </Link>
                                </td>
                                <td className={`${match.winner_player_id === match.player_1_id ? 'text-badger-green' : ''} p-0.5 text-center`}>{match.score_1}</td>
                                <td className={`${match.winner_player_id === match.player_2_id ? 'text-badger-green' : ''} p-0.5 text-center`}>{match.score_2}</td>
                                <td><Link
                                    as="td"
                                    to={`/pool/player/${match.player_2_id}`}
                                    className="hover:underline cursor-pointer"
                                >
                                    {match.player_2}
                                </Link>
                                </td>
                                <td>{match.game_datetime.toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </PageBody>
    );
};
