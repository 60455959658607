import { useState } from 'react';
import { H2Heading } from '../Headers';
import { Link } from 'react-router-dom';

export const PaginatedMatches = ({ matches, groupUrlSlug, itemsPerPage = 25 }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(matches.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentMatches = matches.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        // Scroll to top of matches section
        document.getElementById('matches-section').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div id="matches-section" className="mb-6">
            <H2Heading>All Matches</H2Heading>
            <p className="mb-1">See below a list of games, ordered by recency. Games are weighted by recency to impact the final ratings.</p>
            
            <div className="my-4 flex justify-center">
                <Link
                    to={`/pool/predictions/${groupUrlSlug}`}
                    className="text-badger-blue border-badger-blue border-2 py-1 sm:py-2 px-2 sm:px-4 rounded-full hover:bg-badger-blue hover:text-white"
                >
                    View group ratings over time
                </Link>
            </div>

            <div className="overflow-x-auto">
                <table className="w-full text-sm sm:text-base text-center">
                    <thead>
                        <tr className="bg-gray-200 tracking-tight">
                            <th className="p-1">Game #</th>
                            <th className="p-1">Player 1</th>
                            <th className="p-1">Score 1</th>
                            <th className="p-1">Score 2</th>
                            <th className="p-1">Player 2</th>
                            <th className="p-1">Game Date</th>
                            <th className="p-1">Fixture Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentMatches.map((match) => (
                            <tr key={match.new_id} className={match.new_id % 2 === 0 ? 'bg-gray-50' : ''}>
                                <td className="p-1">{match.new_id}</td>
                                <td className="p-1">
                                    <Link 
                                        to={`/pool/player/${match.player_1_id}`}
                                        className="text-badger-blue hover:underline"
                                    >
                                        {match.player_1}
                                    </Link>
                                </td>
                                <td className={`p-1 ${match.winner_player_id === match.player_1_id ? 'text-badger-green' : ''}`}>
                                    {match.score_1}
                                </td>
                                <td className={`p-1 ${match.winner_player_id === match.player_2_id ? 'text-badger-green' : ''}`}>
                                    {match.score_2}
                                </td>
                                <td className="p-1">
                                    <Link 
                                        to={`/pool/player/${match.player_2_id}`}
                                        className="text-badger-blue hover:underline"
                                    >
                                        {match.player_2}
                                    </Link>
                                </td>
                                <td className="p-1">{new Date(match.game_datetime).toLocaleDateString()}</td>
                                <td className="p-1">{match.fixture_weight}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {totalPages > 1 && (
                <div className="flex justify-center items-center gap-2 mt-4">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`px-3 py-1 rounded ${
                            currentPage === 1
                                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                                : 'bg-badger-blue text-white hover:bg-badger-blue/80'
                        }`}
                    >
                        Previous
                    </button>
                    <span className="text-sm">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`px-3 py-1 rounded ${
                            currentPage === totalPages
                                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                                : 'bg-badger-blue text-white hover:bg-badger-blue/80'
                        }`}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
}; 