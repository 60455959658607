import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { H2Heading, H3Heading } from "../../components/Headers";
import { PageBody } from "../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { getGroup, getGroupMatches, getGroupPlayers } from "../../api/pool";
import { Loading } from "../../components/Loading";
import { createFixtureMatrix, createResultsVectors, createWeightedFixtureMatrix, createWeightedResultsVectors, generateRatings, summariseMatches } from "../../components/utils/ColleyCalculations";

export const RatingsExplanation = () => {
    let { groupUrlSlug } = useParams();
    const [group, setGroup] = useState(null);
    const [players, setPlayers] = useState(null);
    const [matches, setMatches] = useState(null);
    const [fixtureMatrix, setFixtureMatrix] = useState(null);
    const [weightedFixtureMatrix, setWeightedFixtureMatrix] = useState(null);
    const [resultsVector, setResultsVector] = useState(null);
    const [weightedResultsVector, setWeightedResultsVector] = useState(null);
    const [ratings, setRatings] = useState(null);
    const [weightedRatings, setWeightedRatings] = useState(null);

    const results = useQueries([
        { queryKey: ["poolGroup", groupUrlSlug], queryFn: () => getGroup(groupUrlSlug) },
        { queryKey: ["poolGroupPlayers", groupUrlSlug], queryFn: () => getGroupPlayers(groupUrlSlug) },
        { queryKey: ["poolGroupMatches", groupUrlSlug], queryFn: () => getGroupMatches(groupUrlSlug) },
    ]);

    const isLoading = results.some(query => query.isLoading);
    const isError = results.some(query => query.isError);
    const errors = results.map((query) => {
        return query.error;
    });

    useEffect(() => {
        if (!isError && !isLoading) {
            setGroup(results[0].data.data);
            setPlayers(results[1].data.data);
            let matches = results[2]['data']['data'].map(match => {
                return {
                    ...match,
                    game_datetime: new Date(match.game_datetime.replace(/-/g, "/")),
                    win_weight: 1,
                }
            }).sort((a, b) => b.new_id - a.new_id);

            const resultsRatingsArray = createResultsVectors(results[1].data.data, matches);

            setMatches(matches);
            setFixtureMatrix(createFixtureMatrix(results[1].data.data, matches));
            setWeightedFixtureMatrix(createWeightedFixtureMatrix(results[1].data.data, matches));
            setResultsVector(resultsRatingsArray[0]);
            setWeightedResultsVector(createWeightedResultsVectors(results[1].data.data, matches));
            setRatings(generateRatings(createFixtureMatrix(results[1].data.data, matches), resultsRatingsArray[0]));
            setWeightedRatings(generateRatings(createWeightedFixtureMatrix(results[1].data.data, matches), createWeightedResultsVectors(results[1].data.data, matches)));
        }
    }, [isError, isLoading]);

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return <span>Error: {errors[0].message}</span>;
    }

    if (group === null || players === null || matches === null) {
        return <Loading />;
    }

    if (!isError && !isLoading && group === null) {
        return <div>Group not found.</div>;
    }

    const weightings = [...new Set(matches.map(match => match.fixture_weight))];
    const weightPerformance = {};
    for (let i = 0; i < weightings.length; i++) {
        weightPerformance[weightings[i]] = summariseMatches(players, matches.filter(match => match.fixture_weight === weightings[i]));
    }

    return (
        <PageBody>
            <Helmet>
                <title>{group.name} Pool Group Ratings Explanation | VizBadger</title>
                <meta name="description" content="Detailed explanation of how the pool group ratings are calculated using the Colley Matrix System."></meta>
            </Helmet>
            <div className="mb-6">
                <H2Heading>How To Calculate Colley Ratings</H2Heading>
                <H3Heading>Fixture Matrix</H3Heading>
                <p className="mb-1 italic">A major part of the calculation of the rankings is the fixture matrix, that tracks the number of games between each of the players in the group.</p>
                <p className="mb-1 italic">Note: for N player, the Nth position in their array will represent their total games + 2.</p>
                <p className="mb-1 italic">The other numbers are the negative of the total matches between the player at that index position. This shows how many games have been played between each player. It is worth noting here that not all players have to have played each other to get a ranking in the group. That is one of the benefits of this Colley Ranking System.</p>
                <p className="mb-1 italic">i.e. [ 43, -13, -7, -21 ] has played 41 games and played 13 games against the second player, 7 against the third etc.</p>
                <div className="flex flex-col items-center justify-center">
                    <table className="text-center">
                        <thead className="text-sm sm:text-base tracking-tight font-semibold">
                            <tr>
                                <td className="border-r border-b"></td>
                                {players.map((player) => (
                                    <th key={player.name} className="px-2 py-1 border-b">{player.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {fixtureMatrix.map((row, i) => (
                                <tr key={i}>
                                    <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">{players[i].name}</td>
                                    {row.map((value, j) => (
                                        <td key={j} className="p-2">{value}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <table className="text-center">
                        <thead className="text-sm sm:text-base tracking-tight font-semibold">
                            <tr>
                                <td className="border-r border-b"></td>
                                {players.map((player) => (
                                    <th key={player.name} className="px-2 py-1 border-b">{player.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">Results Score</td>
                                {players.map((player, i) => (
                                    <td key={i} className="p-2">{resultsVector[i].toFixed(2)}</td>
                                ))}
                            </tr>
                            <tr className="text-gray-600">
                                <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">Weighted</td>
                                {players.map((player, i) => (
                                    <td key={i} className="p-2">{weightedResultsVector[i].toFixed(2)}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mb-6">
                <H2Heading>Generating Player Ratings</H2Heading>
                <p className="mb-2">We can then bring the two elements together and solve them linearly to calculate a rating for each player.</p>
                <div className="flex flex-col items-center justify-center">
                    <table className="text-center">
                        <thead className="text-sm sm:text-base tracking-tight font-semibold">
                            <tr>
                                <td className="border-r border-b"></td>
                                {players.map((player) => (
                                    <th key={player.name} className="px-2 py-1 border-b">{player.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">Rating</td>
                                {ratings.map((rating, i) => (
                                    <td key={i} className="p-2">{rating}</td>
                                ))}
                            </tr>
                            <tr className="text-gray-600">
                                <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">Weighted</td>
                                {weightedRatings.map((weightedRating, i) => (
                                    <td key={i} className="p-2">{weightedRating}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mb-6 italic">
                <H2Heading classes="not-italic">Fixture Weight Intuition</H2Heading>
                <p className="mb-2 not-italic">If we split out the results on the basis of performance during those fixture weight groups, then we can start to understand why the ratings have changed.</p>
                <div className="flex flex-wrap">
                    {players.map((player, i) => (
                        <div key={i} className="w-full sm:w-1/3 flex flex-col items-center justify-center mb-3">
                            <p className="text-sm sm:text-base tracking-tight font-semibold">{players[i].name}</p>
                            <p className={`mb-2 flex items-center ${ratings[i] > weightedRatings[i] ? 'text-badger-red': 'text-badger-green'}`}>
                                {ratings[i]} <ArrowRightIcon className="mx-1 w-4 h-4"/> {weightedRatings[i]}
                            </p>
                            <table className="text-center">
                                <thead className="text-xs sm:text-sm tracking-tight font-semibold">
                                    <tr>
                                        <td className="border-r border-b"></td>
                                        <th className="px-2 py-1 border-b">Played</th>
                                        <th className="px-2 py-1 border-b">Wins</th>
                                        <th className="px-2 py-1 border-b">Losses</th>
                                        <th className="px-2 py-1 border-b">Win %</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {weightings.map((weighting) => (
                                        <tr key={weighting} className={`${weightPerformance[weighting][i][0] === 0 ? 'text-gray-400' : ''}`}>
                                            <td className="text-xs sm:text-sm tracking-tight font-semibold p-1 border-r">{weighting}</td>
                                            {weightPerformance[weighting][i].slice(2).map((value, j) => (
                                                <td key={j}>{weightPerformance[weighting][i][j]}</td>
                                            ))}
                                            <td>
                                                {weightPerformance[weighting][i][1] > 0 
                                                    ? (100.0 * weightPerformance[weighting][i][1] / weightPerformance[weighting][i][0]).toFixed(1) 
                                                    : 0}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
                <p className="mb-2">The movements in ratings tend towards that those with better results more recently have seen the improvement in the downgrade of importance to older results.</p>
                <p className="mb-2">It is difficult to pinpoint some players, but you have to keep in mind that wins against certain players become less value when the fixtures change. The Colley system doesn't award points for a win based on the strength of the opponent at the time of the game (like in the ELO ranking system), but instead balances out all results each time a game is played.</p>
                <p className="mb-2">I.e. if you beat the top then-rated player early in the league when everyone is rated equally but they subsequently lost a lot of rating (they started stronger than their true skill, and eventually lost a lot more games to other players) that game becomes less valuable for your personal rating because the win was against a player that wasn't really as strong as they seemed at the time.</p>
                <p className="mb-2"><span className="font-semibold italic">A win that lowers an opponent's rating impacts players that aren't playing in the game.</span> Lowering the rating of the top player belittles the wins that other players have against him as well as raising the impact of fixtures that they have had against you.</p>
            </div>
        </PageBody>
    );
}; 