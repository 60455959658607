import { useState } from 'react';
import { useQuery } from 'react-query';
import { getGroupPlayers, getGroupMatches } from '../api/pool';
import { createWeightedFixtureMatrix, createWeightedResultsVectors, generateRatings } from './utils/ColleyCalculations';

export const MatchSimulator = ({ selectedGroup }) => {
    const [player1, setPlayer1] = useState('');
    const [player2, setPlayer2] = useState('');

    // Fetch players and matches for the selected group
    const { data: playersData, isLoading: playersLoading } = useQuery(
        ['poolGroupPlayers', selectedGroup],
        () => getGroupPlayers(selectedGroup)
    );

    const { data: matchesData, isLoading: matchesLoading } = useQuery(
        ['poolGroupMatches', selectedGroup],
        () => getGroupMatches(selectedGroup)
    );

    // Calculate potential outcomes when both players are selected
    const calculateOutcomes = () => {
        if (!player1 || !player2 || !playersData?.data || !matchesData?.data) return null;

        const players = playersData.data;
        const matches = matchesData.data.map(match => ({
            ...match,
            fixture_weight: parseFloat(match.fixture_weight || 1),
            win_weight: parseFloat(match.win_weight || 1)
        }));
        
        const p1Data = players.find(p => p.id === parseInt(player1));
        const p2Data = players.find(p => p.id === parseInt(player2));

        if (!p1Data || !p2Data) return null;

        // Calculate current ratings once
        const currentFixtureMatrix = createWeightedFixtureMatrix(players, matches);
        const currentResultsVector = createWeightedResultsVectors(players, matches);
        const currentRatings = generateRatings(currentFixtureMatrix, currentResultsVector);

        // Calculate current ranks
        const getCurrentRanks = (ratings) => {
            const ratingWithIndex = ratings.map((rating, index) => ({
                rating,
                playerIndex: index
            }));
            const sortedRatings = [...ratingWithIndex].sort((a, b) => b.rating - a.rating);
            return sortedRatings.reduce((acc, curr, index) => {
                acc[curr.playerIndex] = index + 1;
                return acc;
            }, {});
        };

        const currentRanks = getCurrentRanks(currentRatings);

        // Helper function to simulate new ratings after a match
        const simulateMatch = (winner, loser) => {
            // Clone the matches array and add the new match
            const simulatedMatches = [...matches, {
                deleted_at: null,
                fixture_weight: 1,
                game_datetime: new Date().toISOString(),
                match_id: matches.length + 1,
                match_type: "English",
                match_type_id: 1,
                new_id: matches.length + 1,
                player_1: p1Data.name,
                player_1_id: p1Data.id,
                player_2: p2Data.name,
                player_2_id: p2Data.id,
                score_1: winner.id === p1Data.id ? 10 : 5,
                score_2: winner.id === p2Data.id ? 10 : 5,
                session_id: matches[matches.length - 1]?.session_id || 1,
                session_match_end_id: null,
                winner_player_id: winner.id,
                win_weight: 1
            }];

            // Calculate new ratings using the Colley method
            const fixtureMatrix = createWeightedFixtureMatrix(players, simulatedMatches);
            const resultsVector = createWeightedResultsVectors(players, simulatedMatches);
            const newRatings = generateRatings(fixtureMatrix, resultsVector);
            const newRanks = getCurrentRanks(newRatings);

            // Create a map of player name to their old and new ratings/ranks
            return players.reduce((acc, player, index) => {
                acc[player.name] = {
                    oldRating: parseFloat(currentRatings[index]),
                    newRating: parseFloat(newRatings[index]),
                    oldRank: currentRanks[index],
                    newRank: newRanks[index]
                };
                return acc;
            }, {});
        };

        // Simulate both scenarios
        const player1WinsSimulation = simulateMatch(p1Data, p2Data);
        const player2WinsSimulation = simulateMatch(p2Data, p1Data);

        return {
            player1Wins: {
                ratings: player1WinsSimulation,
                winner: p1Data,
                loser: p2Data
            },
            player2Wins: {
                ratings: player2WinsSimulation,
                winner: p2Data,
                loser: p1Data
            }
        };
    };

    const outcomes = calculateOutcomes();

    const renderPlayerRatings = (ratings, winner, loser) => {
        if (!ratings) return null;

        // Sort entries by final ranking (newRank if changed, otherwise oldRank)
        const sortedEntries = Object.entries(ratings).sort((a, b) => {
            const aData = a[1];
            const bData = b[1];
            // Use newRank if rank changed, otherwise use oldRank
            const aRank = aData.oldRank !== aData.newRank ? aData.newRank : aData.oldRank;
            const bRank = bData.oldRank !== bData.newRank ? bData.newRank : bData.oldRank;
            return aRank - bRank;
        });

        // Find the longest player name for consistent column width
        const maxNameLength = Math.max(...sortedEntries.map(([name]) => name.length));

        const numericClasses = "tabular-nums slashed-zero tracking-tight font-feature-settings: 'tnum' on, 'zero' on, 'ss01' on";

        return sortedEntries.map(([playerName, data]) => {
            const ratingChange = data.newRating - data.oldRating;
            const isWinner = playerName === winner.name;
            const isLoser = playerName === loser.name;
            
            let rankChange = `${data.oldRank}`;  // Always show current rank
            let rankColorClass = '';
            if (data.oldRank !== data.newRank) {
                const arrow = data.newRank < data.oldRank ? '↑' : '↓';
                rankChange = `${data.oldRank} ${arrow} ${data.newRank}`;
                rankColorClass = data.newRank < data.oldRank ? 'text-badger-green' : 'text-badger-red';
            }

            // Determine colors based on changes
            const ratingColorClass = ratingChange !== 0 
                ? ratingChange > 0 ? 'text-badger-green' : 'text-badger-red'
                : '';

            // Determine underline style for involved players
            const nameUnderlineClass = isWinner 
                ? 'border-b-2 border-badger-green' 
                : isLoser 
                    ? 'border-b-2 border-badger-red'
                    : 'border-b-2 border-transparent';

            return (
                <div key={playerName} className="grid grid-cols-[auto_1fr_auto] gap-2 items-center text-sm py-[1px]">
                    <span className={`whitespace-nowrap ${nameUnderlineClass}`} style={{ width: `${maxNameLength}ch` }}>
                        {playerName}:
                    </span>
                    <span className={`${ratingColorClass} ${numericClasses}`}>
                        {data.oldRating.toFixed(2)} → {data.newRating.toFixed(2)}
                        {ratingChange !== 0 && (
                            <span className='ml-2'>
                                ({ratingChange > 0 ? '+' : ''}{ratingChange.toFixed(2)})
                            </span>
                        )}
                    </span>
                    <span className={`text-right ${numericClasses} ${rankColorClass}`}>
                        {rankChange}
                    </span>
                </div>
            );
        });
    };

    return (
        <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 mb-8">
            <h2 className="text-xl font-semibold text-gray-900 font-nunito mb-6">Match Simulator</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div>
                    <label htmlFor="player1" className="block text-sm font-medium text-gray-700 mb-2">
                        Player 1
                    </label>
                    <select
                        id="player1"
                        value={player1}
                        onChange={(e) => setPlayer1(e.target.value)}
                        className="w-full rounded-md border border-gray-300 py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-badger-blue"
                    >
                        <option value="">Select Player 1</option>
                        {!playersLoading && playersData?.data.map((player) => (
                            <option 
                                key={player.id} 
                                value={player.id}
                                disabled={player.id.toString() === player2}
                            >
                                {player.name}{player.id.toString() === player2 ? ' (already selected)' : ''}
                            </option>
                        ))}
                    </select>
                </div>
                
                <div>
                    <label htmlFor="player2" className="block text-sm font-medium text-gray-700 mb-2">
                        Player 2
                    </label>
                    <select
                        id="player2"
                        value={player2}
                        onChange={(e) => setPlayer2(e.target.value)}
                        className="w-full rounded-md border border-gray-300 py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-badger-blue"
                    >
                        <option value="">Select Player 2</option>
                        {!playersLoading && playersData?.data.map((player) => (
                            <option 
                                key={player.id} 
                                value={player.id}
                                disabled={player.id.toString() === player1}
                            >
                                {player.name}{player.id.toString() === player1 ? ' (already selected)' : ''}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {outcomes && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="bg-green-50 p-4 rounded-lg">
                        <h3 className="text-lg font-semibold text-green-800 mb-3">
                            If {outcomes.player1Wins.winner.name} wins:
                        </h3>
                        <div className="space-y-1">
                            {renderPlayerRatings(outcomes.player1Wins.ratings, outcomes.player1Wins.winner, outcomes.player1Wins.loser)}
                        </div>
                    </div>

                    <div className="bg-blue-50 p-4 rounded-lg">
                        <h3 className="text-lg font-semibold text-blue-800 mb-3">
                            If {outcomes.player2Wins.winner.name} wins:
                        </h3>
                        <div className="space-y-1">
                            {renderPlayerRatings(outcomes.player2Wins.ratings, outcomes.player2Wins.winner, outcomes.player2Wins.loser)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}; 