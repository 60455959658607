import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getGroupMatches, getGroupPlayers, getGroup } from "../../api/pool";
import { PageHeader, H2Heading, H3Heading } from "../../components/Headers";
import { Loading } from "../../components/Loading";
import { PageBody } from "../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { createWeightedFixtureMatrix, createWeightedResultsVectors, generateRatings, summariseMatches } from "../../components/utils/ColleyCalculations";
import { GridDivContainer, GridDiv } from "../../components/GridDiv";
import { PaginatedMatches } from "../../components/pool/PaginatedMatches";

export const PoolGroup = () => {
    let { groupUrlSlug } = useParams();
    const [group, setGroup] = useState(null);
    const [players, setPlayers] = useState(null);
    const [matches, setMatches] = useState(null);
    const [weightedRatings, setWeightedRatings] = useState(null);
    const [playerSummaries, setPlayerSummaries] = useState(null);

    const results = useQueries([
        { queryKey: ["poolGroup", groupUrlSlug], queryFn: () => getGroup(groupUrlSlug) },
        { queryKey: ["poolGroupPlayers", groupUrlSlug], queryFn: () => getGroupPlayers(groupUrlSlug) },
        { queryKey: ["poolGroupMatches", groupUrlSlug], queryFn: () => getGroupMatches(groupUrlSlug) },
    ]);

    const isLoading = results.some(query => query.isLoading);
    const isError = results.some(query => query.isError);
    const errors = results.map((query) => {
        return query.error;
    });

    useEffect(() => {
        if (!isError && !isLoading) {
            setGroup(results[0].data.data);
            setPlayers(results[1].data.data);
            let matches = results[2]['data']['data'].map(match => {
                return {
                    ...match,
                    game_datetime: new Date(match.game_datetime.replace(/-/g, "/")),
                    win_weight: 1,
                }
            }).sort((a, b) => b.new_id - a.new_id);

            setMatches(matches);
            const ratings = generateRatings(
                createWeightedFixtureMatrix(results[1].data.data, matches),
                createWeightedResultsVectors(results[1].data.data, matches)
            );
            setWeightedRatings(ratings);

            const playerPWL = summariseMatches(results[1].data.data, matches);
            const summaries = Array(results[1].data.data.length);
            for (let i = 0; i < results[1].data.data.length; i++) {
                summaries[i] = {
                    "player_id": results[1].data.data[i]["id"],
                    "name": results[1].data.data[i]["name"],
                    "games_played": playerPWL[i][0],
                    "wins": playerPWL[i][1],
                    "losses": playerPWL[i][2],
                    "win_percentage": (100.0*playerPWL[i][1]/playerPWL[i][0]).toFixed(1),
                    "weighted_win_percentage": playerPWL[i][4] === 0 ? '-' : (100.0*playerPWL[i][3]/playerPWL[i][4]).toFixed(1),
                    "rating": ratings[i],
                };
            };
            summaries.sort((a, b) => b.rating - a.rating);
            setPlayerSummaries(summaries);
        }
    }, [isError, isLoading]);

    if (isLoading) {
        return <Loading />
    };

    if (isError) {
        return <span>Error: {errors[0].message}</span>
    };

    if (group === null || players === null || matches === null) {
        return <Loading />
    };

    if (!isError && !isLoading && group === null) {
        return <div>Group not found.</div>
    };

    return (
        <PageBody>
            <Helmet>
                <title>{group.name} Pool Group Ratings | VizBadger</title>
                <meta name="description" content="Recorded games between amateur pool players to create dynamic ranking table to crown the winner."></meta>
            </Helmet>
            <PageHeader
                title={group.name}
                subtitle="Amateur Pool Matches"
                subtitleIsLink={true}
                linkDestination="/pool/matches"
            />

            <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                    <H3Heading>Current Standings</H3Heading>
                    <Link 
                        to={`/pool/group/${groupUrlSlug}/ratings-explanation`}
                        className="text-badger-blue hover:underline text-sm"
                    >
                        How are ratings calculated?
                    </Link>
                </div>
                <table className="w-full table-fixed">
                    <thead className="text-center">
                        <tr className="bg-gray-200 tracking-tight">
                            <th className="w-6 sm:w-12 p-1">#</th>
                            <th>Player</th>
                            <th className="hidden sm:table-cell">Games</th>
                            <th>Wins</th>
                            <th>Losses</th>
                            <th>Win %</th>
                            <th className="hidden sm:table-cell">Weighted Win %</th>
                            <th>Rating</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {playerSummaries.map((player, i) => (
                            <tr key={i} className={i === 0 ? "border-b border-badger-green bg-badger-green/10" : i === players.length - 1 ? "border-t border-badger-red bg-badger-red/10" : ""}>
                                <td className="p-1">{i + 1}.</td>
                                <td className="p-1 hover:cursor-pointer hover:text-badger-blue hover:underline">
                                    <Link
                                        to={"/pool/player/" + player.player_id}>{player.name}
                                    </Link>
                                </td>
                                <td className="p-1 hidden sm:table-cell">{player.games_played}</td>
                                <td className="p-1">{player.wins}</td>
                                <td className="p-1">{player.losses}</td>
                                <td className="p-1">{player.win_percentage}%</td>
                                <td className="p-1 hidden sm:table-cell">{player.weighted_win_percentage}{player.weighted_win_percentage !== '-' ? '%' : ''}</td>
                                <td className="p-1">{player.rating}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="mb-6">
                <H3Heading>Group Players</H3Heading>
                <GridDivContainer>
                    {players.map(player =>
                        <GridDiv
                            key={player.name}
                            widthClasses="px-1 w-1/4 sm:w-1/4"
                        >
                            <Link
                                className="flex border border-gray-200 rounded text-badger-blue border-badger-blue border-2 font-semibold cursor-pointer p-2 sm:p-3 my-1 text-center align-center justify-center tracking-tight hover:bg-badger-blue hover:text-white"
                                to={`/pool/player/${player.id}`}
                            >
                                {player.name}
                            </Link>
                        </GridDiv>
                    )}
                </GridDivContainer>
            </div>

            <PaginatedMatches matches={matches} groupUrlSlug={groupUrlSlug} />
        </PageBody>
    );
};
