import { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { Link } from "react-router-dom";
import { getGroup, getGroupMatches, getGroupPlayers } from "../../api/pool";
import { Loading } from "../Loading";
import { createWeightedFixtureMatrix, createWeightedResultsVectors, generateRatings } from "../utils/ColleyCalculations";


export const GroupRankingTable = ({ groupUrlSlug, divClasses, highlightedPlayerID = false }) => {
    const [group, setGroup] = useState(null);
    const [players, setPlayers] = useState(null);
    const [matches, setMatches] = useState(null);
    // const [weightedRatings, setWeightedRatings] = useState(null);
    const [rankedRatings, setRankedRatings] = useState(null);

    const results = useQueries([
        { 
            queryKey: ["poolGroup", groupUrlSlug], 
            queryFn: () => getGroup(groupUrlSlug),
            staleTime: 0, // Consider data stale immediately
            cacheTime: 0  // Don't cache the data
        },
        { 
            queryKey: ["poolGroupPlayers", groupUrlSlug], 
            queryFn: () => getGroupPlayers(groupUrlSlug),
            staleTime: 0,
            cacheTime: 0
        },
        { 
            queryKey: ["poolGroupMatches", groupUrlSlug], 
            queryFn: () => getGroupMatches(groupUrlSlug),
            staleTime: 0,
            cacheTime: 0
        },
    ]);

    const isLoading = results.some(query => query.isLoading);
    const isError = results.some(query => query.isError);
    const errors = results.map((query) => {
        return query.error;
    });

    useEffect(() => {
        if (!isError && !isLoading) {
            setGroup(results[0].data.data);
            setPlayers(results[1].data.data);
            let matches = results[2]['data']['data'].map(match => {
                return {
                    ...match,
                    game_datetime: new Date(match.game_datetime.replace(/-/g, "/")), // Define game_datetime as Date
                    win_weight: 1,
                    // fixture_weight: match.new_id <= results[2]['data']['data'].length - 50 ? match.new_id <= results[2]['data']['data'].length - 100 ? 0.8 : 0.9 : 1
                }
            }).sort((a, b) => b.new_id - a.new_id);
            setMatches(matches);
            const rankingsCreation = generateRatings(createWeightedFixtureMatrix(results[1].data.data, matches), createWeightedResultsVectors(results[1].data.data, matches));
            // setWeightedRatings(rankingsCreation);

            let playerRatingLink = [];

            for (let i = 0; i < results[1].data.data.length; i++) {
                const player = results[1].data.data[i];
                playerRatingLink[i] = { "player": player.name, "rating": rankingsCreation[i], "player_id": player.id };
            };

            setRankedRatings(playerRatingLink.sort((a, b) => b.rating - a.rating));
        };
    // eslint-disable-next-line
    }, [isError, isLoading, results[0].data, results[1].data, results[2].data]);

    if (isLoading) {
        return (
            <div className={`w-full ${divClasses}`}>
            <table className="w-full">
                <thead className="text-center">
                    <tr className="bg-gray-200 tracking-tight">
                        <th colSpan={3} className="p-1">...</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                    </tr>
                    <tr>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                    </tr>
                    <tr>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                    </tr>
                </tbody>
            </table>
        </div>
        );
    };

    if (isError) {
        return <span>Error: {errors[0].message}</span>
    };

    if (!isError && !isLoading && group === null) {
        return <div>Group not found.</div>
    };

    if (group === null || players === null || matches === null) {
        return <Loading />
    };

    if (highlightedPlayerID) {
        return (
            <div className={`w-full ${divClasses}`}>
                <table className="w-full">
                    <thead className="text-center">
                        <tr className="bg-gray-200 tracking-tight">
                            <th colSpan={3} className="p-1">{group.name}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rankedRatings.map((rating, i) => (
                            <tr key={i} className={`${i === 0 ? "border-b border-badger-green bg-badger-green/10" : i === players.length - 1 ? "border-t border-badger-red bg-badger-red/10" : rating.player_id === highlightedPlayerID ? "border-t border-b border-badger-yellow bg-badger-yellow/10" : ""} ${rating.player_id === highlightedPlayerID ? "font-semibold" : ""}`}>
                                <td className="p-0.5">{i + 1}.</td>
                                <td className="p-0.5">{rating.player}</td>
                                <td className="p-0.5 text-right">{rating.rating}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex flex-row items-center justify-end">
                    <Link
                        to={`/pool/group/${groupUrlSlug}`}
                        className="italic text-xs sm:text-sm text-gray-500 hover:text-badger-blue hover:text-underline hover:cursor-pointer"
                    >See group &#62;</Link>
                </div>
            </div>
        );
    };

    return (
        <div className={`w-full ${divClasses}`}>
            <table className="w-full">
                <thead className="text-center">
                    <tr className="bg-gray-200 tracking-tight">
                        <th colSpan={3} className="p-1">{group.name}</th>
                    </tr>
                </thead>
                <tbody>
                    {rankedRatings.map((rating, i) => (
                        <tr key={i} className={i === 0 ? "border-b border-badger-green bg-badger-green/10" : i === players.length - 1 ? "border-t border-badger-red bg-badger-red/10" : ""}>
                            <td className="p-0.5">{i + 1}.</td>
                            <td className="p-0.5">{rating.player}</td>
                            <td className="p-0.5 text-right">{rating.rating}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex flex-row items-center justify-end">
                <Link
                    to={`/pool/group/${groupUrlSlug}`}
                    className="italic text-xs sm:text-sm text-gray-500 hover:text-badger-blue hover:text-underline hover:cursor-pointer"
                >See group &#62;</Link>
            </div>
        </div>
    );
};
