export const DictionaryDefinition = () => (
    <div>
        <div
            className="max-w-screen-md font-serif border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mb-4"
        >
            <p className="text-4xl">vizbadger</p>
            <p className="text-xl">
                <span className="text-gray-500 italic">/vɪz/ˈbadʒə/</span>&nbsp;&middot;
                noun
            </p>
            <div className="text mt-2">
                <div className="flex flex-wrap items-center">
                    <span className="text-badger-green mr-2">Similar:</span>
                    <span className="border text-gray-600 px-2 py-1 text-sm rounded-full mr-2 my-1">Analytics Engineer</span>
                    <span className="border text-gray-600 px-2 py-1 text-sm rounded-full mr-2 my-1">Data Scientist</span>
                    <span className="border text-gray-600 px-2 py-1 text-sm rounded-full my-1">Sports Badger</span>
                </div>
            </div>
            <p className="text my-2 py-2">
                A person passionate about sports analytics, event predictions, communicating data effectively
                and obscure sporting records.
                Their behaviour often manifests through conversations about graph types, data strategy,
                colour palettes, finding value sports bets and feature engineering to improve predictive power
                (please note that most people find conversations of this kind
                tiresome, laborious and/or uninteresting).
            </p>
            <p className="text-gray-500 italic text-sm mt-2">
                "They won't stop suggesting we plot the data. He is such a
                vizbadger."
            </p>
            <p className="text-gray-500 italic text-sm mt-2">
                "This vizbadger just ranted for 5 minutes about how 7/1 for Jon Rahm is not a good value bet."
            </p>
        </div>
    </div>
);
