import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { PageBody } from '../components/PageBody';
import { getAdminPoolMatches, getPoolGroups, deleteMatch } from '../api/pool';
import { GroupRankingTable } from '../components/pool/GroupRankingTable';
import { MatchSimulator } from '../components/MatchSimulator';
import { useQuery, useQueryClient } from 'react-query';
import { ArrowPathIcon, TrashIcon } from '@heroicons/react/24/outline';

export const Admin = () => {
    const { logout, token } = useAuth();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [authStatus, setAuthStatus] = useState('Verifying...');
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState('banff');
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [adminPassword, setAdminPassword] = useState('');
    const [deleteError, setDeleteError] = useState('');

    // Get all groups
    const { data: groups, isLoading: groupsLoading } = useQuery('poolGroups', getPoolGroups);

    const handleRefreshRankings = async () => {
        setIsRefreshing(true);
        await queryClient.refetchQueries(['poolGroup', selectedGroup]);
        await queryClient.refetchQueries(['poolGroupPlayers', selectedGroup]);
        await queryClient.refetchQueries(['poolGroupMatches', selectedGroup]);
        setIsRefreshing(false);
    };

    // Authentication verification effect
    useEffect(() => {
        const verifyAuth = async () => {
            try {
                const response = await fetch('/api/admin/verify', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                if (response.ok) {
                    setAuthStatus('Successfully authenticated');
                } else {
                    setAuthStatus('Authentication failed');
                    setTimeout(() => {
                        logout();
                        navigate('/login');
                    }, 2000);
                }
            } catch (error) {
                setAuthStatus('Error verifying authentication');
                setTimeout(() => {
                    logout();
                    navigate('/login');
                }, 2000);
            }
        };

        verifyAuth();
    }, [token, logout, navigate]);

    // Data fetching effect
    useEffect(() => {
        const fetchMatches = async () => {
            if (authStatus !== 'Successfully authenticated') return;

            try {
                setLoading(true);
                const response = await getAdminPoolMatches(token);
                // Sort matches by new_id in descending order (most recent first)
                const sortedMatches = response.data.sort((a, b) => b.new_id - a.new_id);
                setMatches(sortedMatches);
            } catch (error) {
                setError('Error loading matches: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, [authStatus, token]);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleDeleteClick = (match) => {
        setSelectedMatch(match);
        setDeleteModalOpen(true);
        setDeleteError('');
    };

    const handleDeleteConfirm = async () => {
        if (!adminPassword) {
            setDeleteError('Admin password is required');
            return;
        }

        try {
            await deleteMatch(selectedMatch.match_id, adminPassword, token);
            
            // Refresh all relevant queries
            await queryClient.refetchQueries(['poolGroups']);
            await queryClient.refetchQueries(['poolGroup']);
            await queryClient.refetchQueries(['poolGroupPlayers']);
            await queryClient.refetchQueries(['poolGroupMatches']);
            
            // Update local state
            setMatches(matches.filter(m => m.match_id !== selectedMatch.match_id));
            
            // Reset modal state
            setDeleteModalOpen(false);
            setSelectedMatch(null);
            setAdminPassword('');
            setDeleteError('');
        } catch (error) {
            setDeleteError('Failed to delete match. Please check your admin password and try again.');
        }
    };

    return (
        <PageBody>
            <div className="min-h-screen bg-white pb-12 pt-4">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900 font-nunito">Admin Dashboard</h1>
                    <div className="flex flex-row sm:flex-row justify-between items-center mt-4">
                        <Link
                            to="/admin/add-match"
                            className="rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-500 transition-colors duration-200"
                        >
                            + Add Match
                        </Link>
                        <button
                            onClick={handleLogout}
                            className="rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white hover:bg-red-500 transition-colors duration-200"
                        >
                            Logout
                        </button>
                    </div>
                </div>
                
                {/* <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 mb-8">
                    <div className="text-center">
                        <h2 className="text-xl font-semibold text-gray-900 font-nunito">Authentication Status</h2>
                        <p className="mt-2 text-sm text-gray-600 font-nunito">{authStatus}</p>
                    </div>
                </div> */}

                <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 mb-8">
                    <div className="flex justify-between items-center mb-6 flex-col sm:flex-row">
                        <h2 className="text-xl font-semibold text-gray-900 font-nunito">Current Group Rankings</h2>
                        <div className="flex items-center gap-2 mt-4 sm:mt-0">
                            <label htmlFor="groupSelect" className="mr-2 text-sm font-medium text-gray-700">Select Group:</label>
                            <select
                                id="groupSelect"
                                value={selectedGroup}
                                onChange={(e) => setSelectedGroup(e.target.value)}
                                className="rounded-md border border-gray-300 py-1 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-badger-blue"
                            >
                                {!groupsLoading && groups?.data.map((group) => (
                                    <option key={group.id} value={group.url_slug}>
                                        {group.name}
                                    </option>
                                ))}
                            </select>
                            <button
                                onClick={handleRefreshRankings}
                                disabled={isRefreshing}
                                className="rounded-md bg-gray-100 p-1 text-gray-600 hover:bg-gray-200 transition-colors duration-200 disabled:opacity-50"
                            >
                                <ArrowPathIcon className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} />
                            </button>
                        </div>
                    </div>
                    <GroupRankingTable groupUrlSlug={selectedGroup} />
                </div>

                <MatchSimulator selectedGroup={selectedGroup} />

                <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200">
                    <h2 className="text-xl font-semibold text-gray-900 font-nunito mb-6">Latest Pool Matches</h2>
                    
                    {loading ? (
                        <div className="text-center text-gray-600">Loading matches...</div>
                    ) : error ? (
                        <div className="text-center text-red-600">{error}</div>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="w-full text-sm sm:text-base text-center">
                                <thead>
                                    <tr className="bg-gray-200 tracking-tight">
                                        <th className="p-2">Date</th>
                                        <th className="p-2">Player 1</th>
                                        <th className="p-2">Score 1</th>
                                        <th className="p-2">Score 2</th>
                                        <th className="p-2">Player 2</th>
                                        <th className="p-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {matches.map((match) => (
                                        <tr key={match.new_id} className="hover:bg-gray-50">
                                            <td className="p-2">{new Date(match.game_datetime).toLocaleDateString()}</td>
                                            <td className="p-2">{match.player_1}</td>
                                            <td className="p-2">{match.score_1}</td>
                                            <td className="p-2">{match.score_2}</td>
                                            <td className="p-2">{match.player_2}</td>
                                            <td className="p-2">
                                                <button
                                                    onClick={() => handleDeleteClick(match)}
                                                    className="text-red-600 hover:text-red-800 p-1 rounded-full hover:bg-red-100"
                                                >
                                                    <TrashIcon className="h-5 w-5" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                {/* Delete Confirmation Modal */}
                {deleteModalOpen && selectedMatch && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                        <div className="bg-white rounded-lg p-6 max-w-md w-full">
                            <h3 className="text-lg font-semibold mb-4">Delete Match Confirmation</h3>
                            <div className="mb-4">
                                <p className="text-gray-600 mb-2">Are you sure you want to delete this match?</p>
                                <div className="bg-gray-100 p-3 rounded">
                                    <p><span className="font-semibold">Date:</span> {new Date(selectedMatch.game_datetime).toLocaleDateString()}</p>
                                    <p><span className="font-semibold">Players:</span> {selectedMatch.player_1} vs {selectedMatch.player_2}</p>
                                    <p><span className="font-semibold">Score:</span> {selectedMatch.score_1} - {selectedMatch.score_2}</p>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Admin Password
                                </label>
                                <input
                                    type="password"
                                    value={adminPassword}
                                    onChange={(e) => setAdminPassword(e.target.value)}
                                    className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-badger-blue"
                                    placeholder="Enter admin password"
                                />
                                {deleteError && (
                                    <p className="text-red-600 text-sm mt-1">{deleteError}</p>
                                )}
                            </div>
                            <div className="flex justify-end gap-3">
                                <button
                                    onClick={() => {
                                        setDeleteModalOpen(false);
                                        setSelectedMatch(null);
                                        setAdminPassword('');
                                        setDeleteError('');
                                    }}
                                    className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDeleteConfirm}
                                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                                >
                                    Delete Match
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </PageBody>
    );
}; 