import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { PageBody } from '../components/PageBody';
import { getPoolPlayers, addMatchResult } from '../api/pool';
import { BackwardIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from 'react-query';

export const AddMatch = () => {
    const [players, setPlayers] = useState([]);
    const [player1, setPlayer1] = useState('');
    const [player2, setPlayer2] = useState('');
    const [score1, setScore1] = useState('');
    const [score2, setScore2] = useState('');
    const [error, setError] = useState('');
    const [isConfirming, setIsConfirming] = useState(false);
    const [loading, setLoading] = useState(true);
    const { token } = useAuth();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const scores = [0, 1, 2, 3, 4, 5, 6, 7, 10];

    useEffect(() => {
        // Only validate if all fields have values
        if (player1 && player2 && score1 && score2) {
            validateForm();
        }
    }, [player1, player2, score1, score2]);

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const response = await getPoolPlayers();
                console.log('API Response:', response); // Debug log
                setPlayers(response.data || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching players:', err); // Debug log
                setError('Error loading players');
                setLoading(false);
            }
        };
        fetchPlayers();
    }, []);

    const validateForm = () => {
        if (!player1 || !player2 || !score1 || !score2) {
            setError('All fields are required');
            return false;
        }
        if (player1 === player2) {
            setError('Players must be different');
            return false;
        }
        if (score1 === '10' && score2 === '10') {
            setError('Only one player can score 10');
            return false;
        }
        if (score1 !== '10' && score2 !== '10') {
            setError('One player must score 10');
            return false;
        }
        setError('');
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        if (!isConfirming) {
            setIsConfirming(true);
            return;
        }

        try {
            await addMatchResult({
                player1Id: parseInt(player1),
                player2Id: parseInt(player2),
                score1: parseInt(score1),
                score2: parseInt(score2)
            }, token);
            
            // Invalidate relevant queries to trigger a refresh
            queryClient.invalidateQueries('poolGroups');
            queryClient.invalidateQueries('poolGroup');
            queryClient.invalidateQueries('poolGroupPlayers');
            queryClient.invalidateQueries('poolGroupMatches');
            
            navigate('/admin');
        } catch (err) {
            setError('Error adding match result');
            setIsConfirming(false);
        }
    };

    const resetForm = () => {
        setPlayer1('');
        setPlayer2('');
        setScore1('');
        setScore2('');
        setError('');
        setIsConfirming(false);
    };

    if (loading) {
        return (
            <PageBody>
                <div className="flex min-h-screen items-start justify-center pt-4">
                    <div>Loading...</div>
                </div>
            </PageBody>
        );
    }

    return (
        <PageBody>
            <div className="flex min-h-screen flex-col items-start pt-2">
                <div
                    onClick={() => navigate('/admin')}
                    className="flex items-center justify-start hover:cursor-pointer rounded py-2 mb-4 text-gray-600 hover:text-badger-blue"
                >
                    <BackwardIcon className="h-6 w-6" />
                    <p className="p-1">Back to Admin Dashboard</p>
                </div>

                <div className="w-full max-w-md mx-auto">
                    <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
                        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 font-nunito mb-8">
                            Add Match Result
                        </h2>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Player 1
                                    </label>
                                    <select
                                        value={player1}
                                        onChange={(e) => {
                                            setPlayer1(e.target.value);
                                            if (!isConfirming) {
                                                setError('');
                                            }
                                        }}
                                        disabled={isConfirming}
                                        className="block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 focus:ring-2 focus:ring-indigo-600 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    >
                                        <option value="">Select Player</option>
                                        {players.map(player => (
                                            <option key={player.id} value={player.id}>
                                                {player.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Score
                                    </label>
                                    <select
                                        value={score1}
                                        onChange={(e) => {
                                            setScore1(e.target.value);
                                            if (!isConfirming) {
                                                setError('');
                                            }
                                        }}
                                        disabled={isConfirming}
                                        className="block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 focus:ring-2 focus:ring-indigo-600 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    >
                                        <option value="">Select Score</option>
                                        {scores.map(score => (
                                            <option key={score} value={score}>
                                                {score}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Player 2
                                    </label>
                                    <select
                                        value={player2}
                                        onChange={(e) => {
                                            setPlayer2(e.target.value);
                                            if (!isConfirming) {
                                                setError('');
                                            }
                                        }}
                                        disabled={isConfirming}
                                        className="block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 focus:ring-2 focus:ring-indigo-600 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    >
                                        <option value="">Select Player</option>
                                        {players.map(player => (
                                            <option key={player.id} value={player.id}>
                                                {player.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Score
                                    </label>
                                    <select
                                        value={score2}
                                        onChange={(e) => {
                                            setScore2(e.target.value);
                                            if (!isConfirming) {
                                                setError('');
                                            }
                                        }}
                                        disabled={isConfirming}
                                        className="block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 focus:ring-2 focus:ring-indigo-600 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    >
                                        <option value="">Select Score</option>
                                        {scores.map(score => (
                                            <option key={score} value={score}>
                                                {score}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {error && (
                                <div className="text-red-600 text-sm text-center">
                                    {error}
                                </div>
                            )}

                            {isConfirming ? (
                                <div className="space-y-4 border-t border-gray-200 pt-4">
                                    <div className="text-center text-gray-600">
                                        Please confirm the match result:
                                        <br />
                                        <span className={score1 === '10' ? 'border-b-2 border-badger-green' : 'border-b-2 border-badger-red'}>
                                            {players.find(p => p.id === parseInt(player1))?.name} {score1}
                                        </span>
                                        {' - '}
                                        <span className={score2 === '10' ? 'border-b-2 border-badger-green' : 'border-b-2 border-badger-red'}>
                                            {score2} {players.find(p => p.id === parseInt(player2))?.name}
                                        </span>
                                    </div>
                                    <div className="flex justify-between pt-6 border-t border-gray-200">
                                        <div className="flex gap-2">
                                            <button
                                                type="button"
                                                onClick={() => setIsConfirming(false)}
                                                className="rounded-md bg-gray-400 px-4 py-2 text-sm font-semibold text-white hover:bg-gray-500 transition-colors duration-200"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                type="button"
                                                onClick={resetForm}
                                                className="rounded-md bg-gray-400 p-2 text-sm font-semibold text-white hover:bg-gray-500 transition-colors duration-200"
                                            >
                                                <ArrowPathIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                        <button
                                            type="submit"
                                            className="rounded-md bg-badger-green px-4 py-2 font-semibold text-white hover:bg-green-500 transition-colors duration-200"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    type="submit"
                                    className="w-full rounded-md bg-badger-purple py-2 px-3 text-sm font-semibold text-white hover:bg-badger-purple/80 transition-colors duration-200"
                                >
                                    Add Result
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </PageBody>
    );
}; 